html {
  color: #222;
  font-family: Roboto, San Francisco, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.4;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

@media (width <= 912px) {
  body {
    font-size: 26px;
  }
}

@media (width <= 820px) {
  body {
    font-size: 24px;
  }
}

@media (width <= 768px) {
  body {
    font-size: 22px;
  }
}

@media (width <= 540px) {
  body {
    font-size: 18px;
  }
}

@media (width <= 420px) {
  body {
    font-size: 18px;
  }
}

@media (width <= 395px) {
  body {
    font-size: 15px;
  }
}

@media (width <= 360px) {
  body {
    font-size: 14px;
  }
}

::-webkit-scrollbar {
  display: none;
}

body {
  color: #edf2f7;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: #071e26 linear-gradient(#0000, #0a1625);
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-x: hidden;
}

page {
  flex-direction: column;
  align-self: center;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  overflow-x: clip;
}

header {
  background: teal;
  justify-content: center;
  align-items: center;
  padding: .4em;
  font-size: 1.6em;
  display: flex;
}

main {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow-y: scroll;
}

footer {
  background-color: #0b2d39;
  flex-direction: row;
  justify-content: center;
  gap: 2em;
  padding: .6em 1em;
  display: flex;
}

.icon {
  fill: #adff2f;
  width: 4em;
  height: 4em;
}

.inhouse-icon {
  fill: #bee3f8;
  width: 1.2em;
  height: 1.2em;
}

.house-title {
  flex-direction: row;
  margin: .4em;
  display: flex;
}

hr {
  border-top: 1px solid #065666;
  height: 1px;
  margin: 0;
  padding: 0;
}

.house-icon {
  margin: .2rem;
  display: flex;
}

.house-text {
  -webkit-hyphens: auto;
  hyphens: auto;
  justify-content: center;
  align-items: center;
  margin-left: .4em;
  font-size: 1.4em;
  display: flex;
}

.nb {
  font-size: 1.4em;
  color: #adff2f !important;
}

.cut {
  max-height: 34vh;
}

.house-descr {
  flex-direction: column;
  gap: 1em;
  margin: 0 .8em;
  font-size: 1.2em;
  overflow-y: scroll;
}

.icon-block {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.icon-title {
  margin-left: .2em;
}

.tree {
  margin: .4em 0 0;
  padding: 0;
  list-style: none;
}

.tree-item {
  position: relative;
}

.tree-item:before {
  content: "";
  background-color: #000;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -20px;
}

.tree-item > ul {
  margin-left: 20px;
}

.button-icon {
  fill: #fff;
  width: 2em;
  height: 2em;
}

.round {
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  width: 4em;
  height: 4em;
  display: flex;
}

.phone-color {
  background-color: #32cd32;
}

.button {
  color: #1d4044;
  background-color: #0ff;
  border-radius: .2em;
  justify-content: center;
  align-items: center;
  margin: auto .4em .4em;
  font-size: 2.4em;
  display: flex;
}

.info-color {
  background-color: #4299e1;
}

.icon-img-bg {
  fill: #fff;
}

.house-note {
  color: #90cdf4;
  text-align: center;
  background-color: #0b2d39;
  border-top: 1px solid teal;
  border-bottom: 1px solid teal;
  margin: .4em 0;
  padding: .4em 0;
  font-size: 1.2em;
}

.green {
  color: #adff2f;
}

.red {
  color: #00bfff;
}

.inline {
  display: block;
}

/*# sourceMappingURL=index.0cfb9481.css.map */
